import axios from 'axios';

export default {
  namespaced: true,

  state: {
    mediaInfo: null,
  },

  getters: {
    mediaInfo(state) {
      return state.mediaInfo;
    },
  },

  mutations: {
    SET_MEDIA_INFO(state, mediaInfo) {
        state.mediaInfo = mediaInfo;
      },
  },

  actions: {

    async index({commit, state}) {
      try {
        let res = await axios.get("global/index");
        commit('SET_MEDIA_INFO', res.data.mediaInfo);

      } catch (e) {
        commit('SET_MEDIA_INFO', null);
      }
    }

  }
}
