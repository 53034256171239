<template>
  <div>

    <el-upload
      class="upload-video-container"
      ref="upload"
      action="#"
      :http-request="doUpload"
      :show-file-list="false"
      accept="video/*"
    >
      <el-button size="small" type="primary">{{ buttonTitle }}</el-button>

    </el-upload>
    <el-button class="remove-video-btn"  v-if="value" @click="handleRemove" size="small" type="primary">
      Remove Video
      <i class="el-icon-delete"></i></el-button>
    <div v-if="value" class="el-upload-list--picture-card">
      <div class="el-upload-list__item" :style="{width:previewWidth,height:previewHeight}">
        <video alt="" :src="getUrl()" class="el-upload-list__item-thumbnail" controls>
        </video>
      </div>

    </div>

  </div>
</template>

<script>

import {Upload, Dialog, Button} from 'element-ui';

export default {

  name: "VideoUploader",

  props: {
    buttonTitle: {
      default: "Click To Upload"
    },
    value: {
      type: String,
    },
    previewWidth: {
      type: String,
      default:"540px"
    },
    previewHeight: {
      type: String,
      default:"auto"
    },
  },

  components: {
    [Upload.name]: Upload,
    [Dialog.name]: Dialog,
    [Button.name]: Button
  },


  data() {
    return {
      dialogImageUrl: '',
      disabled: false,
      dialogVisible: false
    };
  },

  methods: {
    doUpload(params) {
      let bodyFormData = new FormData();
      bodyFormData.append('video', params.file);
      this.axios.post(process.env.VUE_APP_UPLOAD_VIDEO_URL,
        bodyFormData,
        {
          headers: {"Content-Type": "multipart/form-data"},
        }).then(response => {

        this.$emit("input", response.data.data.url);

      }).catch(err => {
        console.log(err.msg)
      })
    },

    handleRemove() {
      this.$emit('input', "");
    },

    handleVideoCardPreview() {
      this.dialogImageUrl = process.env.VUE_APP_SERVER_IMAGE_URL + this.value;
      this.dialogVisible = true;
    },

    getUrl() {
      return process.env.VUE_APP_SERVER_IMAGE_URL + this.value;
    },

  }

}
</script>
<style scoped>
.el-upload-list__item {
  margin-top: 10px;
}
.upload-video-container{
  display: inline;
}
.remove-video-btn{
  margin-left: 10px;
  background-color: red;
  border-color:red ;
}
</style>
