var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"wrapper",class:{'nav-open': _vm.$sidebar.showSidebar}},[_c('notifications'),_c('side-bar',{scopedSlots:_vm._u([{key:"links",fn:function(props){return [_c('sidebar-item',{attrs:{"link":{name: 'Dashboard', icon: 'nc-icon nc-chart-pie-35', path: '/overview'}}}),(_vm.$store.getters['auth/checkAccess']('projects/index'))?_c('sidebar-item',{attrs:{"link":{name: 'Projects', icon: 'nc-icon nc-layers-3', path: '/projects/list'}}}):_vm._e(),(_vm.$store.getters['auth/checkAccess']('media/index'))?_c('sidebar-item',{attrs:{"link":{name: 'Media', icon: 'nc-icon nc-album-2', path: '/media/list'}}}):_vm._e(),(_vm.$store.getters['auth/checkAccess']('newsletter/index'))?_c('sidebar-item',{attrs:{"link":{name: 'Newsletter Subscribers', icon: 'nc-icon nc-email-83', path: '/newsletter'}}}):_vm._e(),(_vm.$store.getters['auth/checkAccess']('contact-us/index'))?_c('sidebar-item',{attrs:{"link":{name: 'Contact Messages', icon: 'nc-icon nc-support-17', path: '/contact-us'}}}):_vm._e(),(_vm.$store.getters['auth/checkAccess']('join-us/index'))?_c('sidebar-item',{attrs:{"link":{name: 'Join Requests', icon: 'nc-icon  nc-single-02', path: '/join-us'}}}):_vm._e(),(_vm.$store.getters['auth/checkAccess']('pages/index')
                          || _vm.$store.getters['auth/checkAccess']('content-pages/create')
                          || _vm.$store.getters['auth/checkAccess']('redirection-pages/create')
                          || _vm.$store.getters['auth/checkAccess']('branches/index'))?_c('sidebar-item',{attrs:{"link":{name: 'Pages', icon: 'nc-icon nc-single-copy-04'}}},[(_vm.$store.getters['auth/checkAccess']('pages/index'))?_c('sidebar-item',{attrs:{"link":{name: 'Existing Pages', path: '/pages/list'}}}):_vm._e(),(_vm.$store.getters['auth/checkAccess']('content-pages/create'))?_c('sidebar-item',{attrs:{"link":{name: 'Add Content Page', path: '/content-pages/create'}}}):_vm._e(),(_vm.$store.getters['auth/checkAccess']('redirection-pages/create'))?_c('sidebar-item',{attrs:{"link":{name: 'Add Redirection Page', path: '/redirection-pages/create'}}}):_vm._e(),(_vm.$store.getters['auth/checkAccess']('branches/index'))?_c('sidebar-item',{attrs:{"link":{name: 'Branches', path: '/branches/list'}}}):_vm._e()],1):_vm._e(),(_vm.$store.getters['auth/checkAccess']('panel-groups/index')
                          || _vm.$store.getters['auth/checkAccess']('panels/index'))?_c('sidebar-item',{attrs:{"link":{name: 'Panels And Banners', icon: 'nc-icon nc-album-2'}}},[(_vm.$store.getters['auth/checkAccess']('panels/index'))?_c('sidebar-item',{attrs:{"link":{name: 'Panels', path: '/panels/list'}}}):_vm._e(),(_vm.$store.getters['auth/checkAccess']('panel-groups/index'))?_c('sidebar-item',{attrs:{"link":{name: 'Groups', path: '/panel-groups/list'}}}):_vm._e()],1):_vm._e(),(_vm.$store.getters['auth/checkAccess']('settings/index')
                  || _vm.$store.getters['auth/checkAccess']('mediaInfo/index'))?_c('sidebar-item',{attrs:{"link":{name: 'Settings', icon: 'nc-icon nc-settings-gear-64'}}},[(_vm.$store.getters['auth/checkAccess']('mediaInfo/index'))?_c('sidebar-item',{attrs:{"link":{name: 'Media Info', path: '/mediaInfo/list'}}}):_vm._e(),(_vm.$store.getters['auth/checkAccess']('settings/index'))?_c('sidebar-item',{attrs:{"link":{name: 'Settings', path: '/settings/list'}}}):_vm._e()],1):_vm._e(),(_vm.$store.getters['auth/checkAccess']('roles/index')
                          || _vm.$store.getters['auth/checkAccess']('permissions/index')
                          || _vm.$store.getters['auth/checkAccess']('users/index'))?_c('sidebar-item',{attrs:{"link":{name: 'Access Management', icon: 'nc-icon nc-circle-09'}}},[(_vm.$store.getters['auth/checkAccess']('roles/index'))?_c('sidebar-item',{attrs:{"link":{name: 'Roles', path: '/roles/list'}}}):_vm._e(),(_vm.$store.getters['auth/checkAccess']('permissions/index'))?_c('sidebar-item',{attrs:{"link":{name: 'Permissions', path: '/permissions/list'}}}):_vm._e(),(_vm.$store.getters['auth/checkAccess']('users/index'))?_c('sidebar-item',{attrs:{"link":{name: 'Users', path: '/users/list'}}}):_vm._e()],1):_vm._e()]}}])},[_c('user-menu'),_c('mobile-menu')],1),_c('div',{staticClass:"main-panel"},[_c('top-navbar'),_c('dashboard-content',{nativeOn:{"click":function($event){return _vm.toggleSidebar($event)}}}),_c('content-footer')],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }