import DashboardLayout from "@/pages/layouts/DashboardLayout";

let joinUsRoutes = {
  path: '/join-us',
  component: DashboardLayout,
  redirect: '/join-us/list',
  children: [
    {
      path: 'list',
      name: 'Join Requests List',
      component:()=>import(/* webpackChunkName: "join-us" */ '@/pages/general/joinUs/JoinUsList'),
      meta: {
        permissionCode: 'join-us/index',
      }
    },
  ]
}

export default joinUsRoutes;
