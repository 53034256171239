import DashboardLayout from "@/pages/layouts/DashboardLayout";

let mediaRoutes = {
  path: '/media',
  component: DashboardLayout,
  redirect: '/media/list',
  children: [
    {
      path: 'list',
      name: 'Media List',
      component:()=>import(/* webpackChunkName: "media" */ '@/pages/media/media/MediaList'),
      meta: {
        permissionCode: 'media/index',
      }
    },
    {
      path: 'create',
      name: 'Add Media',
      component:()=>import(/* webpackChunkName: "media" */ '@/pages/media/media/MediaForms'),
      meta: {
        permissionCode: 'media/create',
      }
    },
    {
      path: 'edit/:id',
      name: 'Edit Media',
      component:()=>import(/* webpackChunkName: "media" */ '@/pages/media/media/MediaForms'),
      meta: {
        permissionCode: 'media/update',
      }
    }
  ]
}

export default mediaRoutes;
