import DashboardLayout from "@/pages/layouts/DashboardLayout";

let projectsRoutes = {
  path: '/projects',
  component: DashboardLayout,
  redirect: '/projects/list',
  children: [
    {
      path: 'list',
      name: 'Projects List',
      component:()=>import(/* webpackChunkName: "projects" */ '@/pages/project/projects/ProjectsList'),
      meta: {
        permissionCode: 'projects/index',
      }
    },
    {
      path: 'create',
      name: 'Add Project',
      component:()=>import(/* webpackChunkName: "projects" */ '@/pages/project/projects/ProjectsForm'),
      meta: {
        permissionCode: 'projects/create',
      }
    },
    {
      path: 'edit/:id',
      name: 'Edit Project',
      component:()=>import(/* webpackChunkName: "products" */ '@/pages/project/projects/ProjectsForm'),
      meta: {
        permissionCode: 'projects/update',
      }
    }
  ]
}

export default projectsRoutes;
